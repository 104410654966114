import React, { useState } from "react";
import { Modal, TextField, PrimaryButton, IconButton, Stack, DefaultButton } from "@fluentui/react";
import { IIconProps } from "@fluentui/react/lib/Icon";
import StarRating from "./StarRating";
import styles from "./FeedbackPopup.module.css";
import { submitFeedback } from "../../api";

interface FeedbackPopupProps {
    isOpen: boolean;
    onClose: () => void;
    question: string;
    answer: string;
}

const cancelIcon: IIconProps = { iconName: "Cancel" };

const FeedbackPopup: React.FC<FeedbackPopupProps> = ({ isOpen, onClose, question, answer }) => {
    const [rating, setRating] = useState<number | null>(null);
    const [issue, setIssue] = useState("");
    const [suggestion, setSuggestion] = useState("");

    const handleIssueChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setIssue(newValue || "");
    };

    const handleSuggestionChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setSuggestion(newValue || "");
    };

    const resetForm = () => {
        setRating(null);
        setIssue("");
        setSuggestion("");
    };

    const handleSubmit = async () => {
        if (rating === null) {
            console.error("Rating is required");
            return;
        }
        const feedback = { rating, issue, suggestion, question, answer };
        try {
            await submitFeedback(feedback);
            console.log("Feedback submitted successfully");
        } catch (error) {
            console.error("Error submitting feedback:", error);
        }

        resetForm();
        onClose();
    };

    const handleCancel = () => {
        resetForm();
        onClose();
    };

    const buttonStyles = {
        root: {
            backgroundColor: "#01C38C",
            borderColor: "#01C38C"
        },
        rootHovered: {
            backgroundColor: "#019C76",
            borderColor: "#019C76"
        }
    };

    return (
        <Modal isOpen={isOpen} onDismiss={handleCancel} isBlocking={false} containerClassName={styles.modalContainer}>
            <div className={styles.header}>
                <span>Share your thoughts on the provided answer</span>
                <IconButton iconProps={cancelIcon} onClick={handleCancel} styles={{ root: { color: "#8F9AAO" }, rootHovered: { color: "#FF375E" } }} />
            </div>
            <div className={styles.body}>
                <label className={styles.label}>
                    Rate the answer correctness and relatedness
                    <span className={styles.mandatory}>*</span>
                </label>
                <StarRating value={rating} onChange={setRating} />
                <div className={styles.inputGroup}>
                    <label className={styles.label}>Report an Issues</label>
                    <TextField multiline rows={4} value={issue} onChange={handleIssueChange} className={styles.textField} />
                </div>
                <div className={styles.inputGroup}>
                    <label className={styles.label}>Suggestions</label>
                    <TextField multiline rows={4} value={suggestion} onChange={handleSuggestionChange} className={styles.textField} />
                </div>
                <Stack horizontal tokens={{ childrenGap: 10 }} className={styles.buttons}>
                    <PrimaryButton onClick={handleSubmit} text="Submit" styles={buttonStyles} disabled={rating === null} />
                    <DefaultButton onClick={handleCancel} text="Cancel" />
                </Stack>
            </div>
        </Modal>
    );
};

export default FeedbackPopup;
