import React from "react";
import { DefaultButton } from "@fluentui/react";
import styles from "./FeedbackButton.module.css";

interface FeedbackButtonProps {
    onClick: () => void;
}

const FeedbackButton: React.FC<FeedbackButtonProps> = ({ onClick }) => {
    return (
        <DefaultButton className={styles.feedbackButton} onClick={onClick}>
            Give Feedback
        </DefaultButton>
    );
};

export default FeedbackButton;
