// src/index.tsx or your router configuration file
import React from "react";
import ReactDOM from "react-dom/client";
import { createHashRouter, RouterProvider } from "react-router-dom";
import { initializeIcons } from "@fluentui/react";
import Login from "./pages/login/Login";
import Chat from "./pages/chat/Chat";
import ProtectedRoute from "./components/ProtectedRoute";
import { AuthProvider } from "./AuthContext";
import "../src/assets/fonts.css";
import "./index.css";
import LayoutWrapper from "./layoutWrapper";

initializeIcons();

const router = createHashRouter([
    {
        path: "/",
        element: <Login />
    },
    {
        path: "chat",
        element: (
            <ProtectedRoute>
                <LayoutWrapper>
                    <Chat />
                </LayoutWrapper>
            </ProtectedRoute>
        )
    }
]);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <AuthProvider>
            <RouterProvider router={router} />
        </AuthProvider>
    </React.StrictMode>
);
