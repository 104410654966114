import styles from "./UserChatMessage.module.css";

interface Props {
    message: string;
    isRTL?: boolean;
}

export const UserChatMessage = ({ message, isRTL = false }: Props) => {
    return (
        <div className={styles.container}>
            <div className={`${styles.message} ${isRTL ? styles.messageRtl : ""}`}>{message}</div>
        </div>
    );
};
