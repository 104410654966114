import React, { useState } from "react";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import StarIcon from "@mui/icons-material/Star";

// Define labels with number keys
const labels: { [index: number]: string } = {
    1: "Useless",
    2: "Poor",
    3: "Ok",
    4: "Good",
    5: "Excellent"
};

function getLabelText(value: number) {
    return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
}

interface StarRatingProps {
    value: number | null;
    onChange: (newValue: number | null) => void;
    precision?: number;
    size?: "small" | "medium" | "large";
}

const StarRating: React.FC<StarRatingProps> = ({ value, onChange, precision = 1, size = "large" }) => {
    const [hover, setHover] = useState(-1);

    return (
        <Box sx={{ width: "100%", marginBottom: "20px" }}>
            <Rating
                name="hover-feedback"
                value={value}
                precision={precision}
                size={size}
                getLabelText={getLabelText}
                onChange={(event, newValue) => {
                    onChange(newValue);
                }}
                onChangeActive={(event, newHover) => {
                    setHover(newHover);
                }}
                emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
            />
            {value !== null && <Box sx={{ ml: 2 }}>{labels[hover !== -1 ? hover : value]}</Box>}
        </Box>
    );
};

export default StarRating;
